import * as React from "react"
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
        <div className="error-page-wrapper d-flex align-items-center">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col xl={7} className="text-center">
                <h1>Something’s gone wrong!</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <Link to="/" className="button button-filled">Homepage</Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/contact/" className="button button-outline">Contact us</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
  </Layout>
)

export const Head = () => <Seo title="404: Not Found" />

export default NotFoundPage